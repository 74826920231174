<template>
  <el-dialog
    :visible.sync="dialogVisible"
    top="2%"
    width="900px"
    title="添加课程"
    :center="true"
    :before-close="doClose"
    :close-on-click-modal="false"
  >
    <section class="CourseTables">
      <div class="operationControl" style="border: 0">
        <div class="searchbox">
          <div title="课程名称" class="searchboxItem">
            <span class="itemLabel">课程名称:</span>
            <el-input
              clearable
              v-model="courseName"
              type="text"
              size="small"
              placeholder="请输入课程名称"
            />
          </div>

          <div title="课程分类" class="searchboxItem">
            <span class="itemLabel">课程分类:</span>
            <el-cascader
                :options="miniCourseTypeList"
                v-model="courseType"
                :props="miniProps"
                size="small"
                clearable
              ></el-cascader>
          </div>
          <div>
            <el-button class="bgc-bv" @click="getData()">查询</el-button>
          </div>
        </div>
      </div>
      <div class="framePage-scroll">
        <div class="ovy-a">
          <el-table
            ref="multipleTable"
            :data="tableData"
            :height="tableHeight"
            size="small"
            tooltip-effect="dark"
            row-key="courseId"
            style="width: 100%"
            @selection-change="change"
            stripe
            border
          >
             <el-table-column
              type="selection"
              width="50px"
              align="center"
              :reserve-selection="true"
            >
            </el-table-column>
            <el-table-column
              label="序号"
              align="center"
              type="index"
              width="100px"
              :index="indexMethod"
            />
            <el-table-column
              label="课程名称"
              align="center"
              prop="courseName"
              show-overflow-tooltip
            />
            <el-table-column
              label="课节"
              align="center"
              prop="coursewareNum"
              show-overflow-tooltip
            />

            <el-table-column
              label="课程分类"
              align="center"
              prop="courseTypeName"
              show-overflow-tooltip
            />
            <el-table-column label="创建时间" align="center" width="120">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.createTime | momentDate }}
                </span>
              </template>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
        </div>
      </div>
      <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      <div class="dialog-footer" style="margin-top: 10px;">
        <div>
          <el-button class="bgc-bv" @click="dialogClose">取消</el-button>
          <el-button class="bgc-bv" @click="doOk">确定</el-button>
        </div>
      </div>
    </section>
  </el-dialog>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "courseMoreTable",
  props:{
    isMini:{//是否为小程序专栏，安知公益关联课程
      type:Boolean,
      default:false
    }
  },
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      courseName: "",
      courseType: '',
       miniCourseTypeList: [], //课程分类
      miniProps: {
        label: "name",
        value: "courseTypeId",
        emitPath: false,
        checkStrictly: true,
      },
      multipleTable: [],
      props: {
        value: "id",
        label: "label",
        emitPath: false,
      },
      dialogVisible: false,
      backArr: [],
      backArrall: [],

    };
  },
  created() {
      
  },
  updated() {
  
  },
  methods: {
   
   
    tinit() {
      this.init();
    },
    change(rowArr) {
      this.backArr = rowArr;
      this.backArrall = rowArr;
      this.multipleTable = rowArr;
    },
    showPopUp() {
      this.dialogVisible = true;
        this.getData();
        this.getcourseTypeArrData()
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        courseName: this.courseName,
      };
      if (this.courseType) {
          params.courseType = this.courseType
      }
      let url = 'minicourse/home/recommend/course/choiceList'
      if(this.isMini) {
        url = 'minicourse/course/list'
        params.salesState = '20'
      }
      this.doFetch(
        {
          url: url,
          params,
          pageNum,
        },
        true,
        2
      );
    },
      //获取课程分类数组数据
    getcourseTypeArrData() {
      this.$post(
        "miniapp/coursetype/selectTree",
        { hiddenState: "20" },
        3000,
        true,
        2
      ).then((ret) => {
        this.miniCourseTypeList = this.delchild(ret.data);
      });
    },
    delchild(list = []) {
      for (let i = 0; i < list.length; i++) {
        const el = list[i];
        if (el.children.length == 0) {
          delete el.children;
        }
        this.delchild(el.children);
      }
      return list;
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = 760 - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    doOk() {
      const { backArr } = this;
      if (backArr.length) {
        this.$emit("eventBus", backArr);
        this.dialogVisible = false;
        this.backArr = [];
        this.$refs.multipleTable.clearSelection();
      } else {
        this.$message({
          message: "请选择要关联的课件",
          type: "warning",
        });
      }
    },
    dialogClose() {
      this.dialogVisible = false;
      this.backArr = [];
      this.$refs.multipleTable.clearSelection();
      //   this.$emit("eventBus");
      // this.params = {};
    },
    doClose() {
      this.dialogVisible = false;
      this.backArr = [];
      this.$refs.multipleTable.clearSelection();
      //   this.$emit("eventBus");
      // this.params = {};
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
  },
};
</script>
<style lang="less">
.CourseTables {
  .el-radio {
    .el-radio__label {
      display: none;
    }
    .el-radio__inner {
      width: 18px;
      height: 18px;
      border-color: #777;
    }
    .el-radio__inner:hover {
      border-color: #5c6be8;
    }
    .el-radio__input.is-checked .el-radio__inner {
      border-color: #5c6be8;
      background-color: #5c6be8;
    }
  }
  .Batch {
    padding: 1rem 1rem;
    .BatchBox {
      display: flex;
      height: 120px;
      border: 1px solid #ebeef5;
      border-radius: 5px;
      .BatchBoxFirst {
        width: 260px;
        background: #f2f7fd;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .BatchBoxSecond {
        width: 100%;
        .BatchforBox {
          display: flex;
          flex-wrap: wrap;
          .BatchforFlex {
            display: flex;
            align-items: center;
            padding: 5px 20px;
            cursor: pointer;
            .BatchImgBox {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            img {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
