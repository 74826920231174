<template>
  <div class="pageContol listWrap templateList formCom">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">专栏管理（小程序）</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">专栏列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a"
            >{{ stu != "edit" ? "新增" : "修改" }}专栏</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="form-box">
              <h3>专栏信息</h3>
              <el-form
                ref="ruleForm"
                label-width="7.5rem"
                class="form"
                :rules="rules"
                :model="ruleForm"
              >
                <el-form-item
                  label="专栏名称："
                  prop="miniSubscribeSpecialName"
                >
                  <el-input
                    v-model="ruleForm.miniSubscribeSpecialName"
                    size="small"
                    maxlength="50"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-form-item label="专栏详情：" required class="form-item">
                  <div
                    ref="editor"
                    class="editor"
                    style="width: 100%; max-width: 1200px"
                  />
                </el-form-item>
                <el-form-item
                  label="专栏封面："
                  prop="miniSubscribeSpecialCoverUrl"
                  :class="[ruleForm.id ? 'item-disabled' : '']"
                >
                  <el-upload
                    :on-change="handlebusinessLicense2"
                    :before-upload="$beforeAvatarUpload"
                    :http-request="$requestMine"
                    :show-file-list="false"
                    class="img-el-upload"
                    action
                    accept="image/png, image/gif, image/jpg, image/jpeg"
                  >
                    <el-image
                      :src="
                        ruleForm.miniSubscribeSpecialCoverUrl ||
                        require('@/assets/develop.png')
                      "
                      fit="contain"
                      class="imgCenter"
                    ></el-image>
                  </el-upload>
                </el-form-item>
                <el-form-item
                  label="专栏有效期："
                  prop="miniSubscribeSpecialTerm"
                >
                  <el-input-number
                    v-model="ruleForm.miniSubscribeSpecialTerm"
                    :min="1"
                    :max="100000"
                    :precision="0"
                    size="small"
                  ></el-input-number>&emsp;天
                </el-form-item>
                <el-form-item
                  label="专栏售价："
                  prop="miniSubscribeSpecialFree"
                >
                  <el-radio-group v-model="ruleForm.miniSubscribeSpecialFree">
                    <el-radio :label="false">付费</el-radio>
                    <el-radio :label="true">免费</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  v-if="!ruleForm.miniSubscribeSpecialFree"
                  label="专栏金额："
                  prop="miniSubscribeSpecialPrice"
                >
                  <el-input-number
                    v-model="ruleForm.miniSubscribeSpecialPrice"
                    :min="0.01"
                    :max="999999.99"
                    :precision="2"
                    size="small"
                  ></el-input-number>
                </el-form-item>
              </el-form>
              <h3>专栏课程</h3>
              <el-button
                class="bgc-bv"
                size="small"
                round
                @click="RelatedCourses()"
                icon="el-icon-plus"
                >关联课程</el-button
              >
              <span style="margin-left: 20px"
                >已关联课程：{{
                  ruleForm.miniSubscribeSpecialCourses.length
                }}</span
              >
              <el-table
                ref="multipleTable"
                :data="ruleForm.miniSubscribeSpecialCourses"
                size="small"
                :header-cell-style="tableHeader"
                style="width: 100%"
                stripe
              >
                <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  width="100px"
                  :index="indexMethod"
                />
                <el-table-column
                  label="课程名称"
                  align="center"
                  prop="courseName"
                  show-overflow-tooltip
                  min-width="100"
                />
                <el-table-column
                  label="上架状态"
                  align="center"
                  prop="salesState"
                  show-overflow-tooltip
                  width="80"
                >
                  <template slot-scope="scope">
                    <span
                      :style="{
                        color: scope.row.salesState == '20'
                          ? '#92C74F'
                          : '#DF354D',
                      }"
                    >
                      {{
                        scope.row.salesState == '20' ? "已上架" : "未上架"
                      }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="关联时间"
                  align="center"
                  prop="relationTime"
                  show-overflow-tooltip
                  min-width="80"
                >
                  <template slot-scope="scope">
                      {{
                        scope.row.relationTime || ''
                      }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="学习人数"
                  align="center"
                  prop="studyNum"
                  show-overflow-tooltip
                  min-width="80"
                />
                <el-table-column label="操作" align="center" width="100">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 7px 10px"
                      @click="
                        handleDel(
                          scope.row.miniSubscribeSpecialId,
                          scope.$index
                        )
                      "
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
                <Empty slot="empty" />
              </el-table>
              <div class="btn-box flexcc">
                <el-button @click="dohandleOk" class="bgc-bv">返 回</el-button>
                <el-button class="bgc-bv" @click="doAddSave('ruleForm')"
                  >保存
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 关联课程的弹框 -->
    <courseTableMore
      v-if="specialCoursesLoading"
      ref="courseTableMore"
      :isMini="true"
      @eventBus="courseTableMoreBack"
    />
  </div>
</template>
<script>
import courseTableMore from "@/views/miniApp/excellentCourses/courseTable";
import Empty from "@/components/Empty.vue";
import E from "wangeditor";
let editor = {};
export default {
  name: "operate/accountEdit",
  components: {
    courseTableMore,
    Empty,
  },
  data() {
    return {
      // 新增&编辑
      stu: "",
      // 专栏信息
      ruleForm: {
        miniSubscribeSpecialId: "", //专栏id
        miniSubscribeSpecialName: "", //专栏名称
        miniSubscribeSpecialInfo: "", //专栏详情
        miniSubscribeSpecialCoverUrl: "", //专栏封面Url
        miniSubscribeSpecialCover: "", //专栏封面key
        miniSubscribeSpecialTerm: "", //专栏有效期
        miniSubscribeSpecialFree: false, //专栏是否免费
        miniSubscribeSpecialPrice: "", //专栏参考价
        miniSubscribeSpecialCourses: [], // 关联课程
      },
      specialCoursesLoading: false, //关联课程弹框
      // 数据校验
      rules: {
        miniSubscribeSpecialName: [
          { required: true, message: "请输入专栏名称", trigger: "blur" },
        ],
        specialSubjectDetail: [
          { required: true, message: "请输入专栏详情", trigger: "change" },
        ],
        miniSubscribeSpecialCoverUrl: [
          { required: true, message: "请上传专栏封面", trigger: "change" },
        ],
        courseIds: [
          { required: true, message: "请选择专栏课程", trigger: "change" },
        ],
        miniSubscribeSpecialTerm: [
          { required: true, message: "请输入专栏有效期", trigger: "change" },
        ],
        miniSubscribeSpecialFree: [
          { required: true, message: "请选择专栏售价", trigger: "change" },
        ],
        miniSubscribeSpecialPrice: [
          { required: true, message: "请输入专栏参考价", trigger: "change" },
        ],
      },
      // 关联课程表格 - 表头样式
      tableHeader: {
        background: "#5C6BE8",
        color: "#fff",
        borderColor: "#5C6BE8",
      },
    };
  },
  created() {
    this.stu = this.$route.query.stu;
    if (this.stu == "edit") {
      this.ruleForm.miniSubscribeSpecialId =
        this.$route.query.miniSubscribeSpecialId;
      this.getDetail(this.$route.query.miniSubscribeSpecialId);
    }
  },
  mounted() {
    this.editorInit();
    const eWidth = this.$refs.editor.clientWidth;
    this.eWidth = eWidth;
  },
  computed: {},
  methods: {
    // 专栏详情
    editorInit() {
      editor = new E(this.$refs.editor);
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo", // 重复
      ];
      editor.config.zIndex = 100;
      editor.config.uploadImgShowBase64 = true;
      editor.config.uploadImgMaxSize = 1 * 1024 * 1024;
      editor.config.pasteFilterStyle = "text";
      editor.config.pasteIgnoreImg = false;
      editor.config.customUploadImg = (files, insert) => {
        let extension = files[0].name.substring(
          files[0].name.lastIndexOf(".") + 1,
          files[0].name.length
        );
        let formData = new FormData();
        formData.append("file ", files[0]);
        formData.append("fileType ", extension);
        formData.append("folder ", "LECTURER");
        formData.append("isPublic ", true);
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            insert(result.data.fileURL);
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      };
      editor.config.onchange = (html) => {
        this.ruleForm.miniSubscribeSpecialInfo = html;
      };
      editor.config.pasteTextHandle = function (content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editor.create();
      const eWidth = this.$refs.editor.clientWidth;
      this.eWidth = eWidth;
    },

    // 专栏封面
    handlebusinessLicense2(res) {
      let file = res.raw;
      let fileType = file.name.slice(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      const that = this;
      this.$Postformat("/sys/upload", formData).then((ret) => {
        that.ruleForm.miniSubscribeSpecialCoverUrl = ret.data.fileURL || "";
        that.ruleForm.miniSubscribeSpecialCover = ret.data.fileKey || "";
      });
    },

    // 关联课程
    RelatedCourses() {
      this.specialCoursesLoading = true;
      this.$nextTick(() => {
        this.$refs.courseTableMore.showPopUp();
      });
    },

    // 关联课程 - 批量选择回调
    courseTableMoreBack(ret) {
      let oldCourseIds = [];
      let manyTimesMessage = 0; // 防止多次提示
      if (this.ruleForm.miniSubscribeSpecialCourses.length > 0) {
        this.ruleForm.miniSubscribeSpecialCourses.map((el1) => {
          oldCourseIds.push(el1.courseId);
        });
        ret.map((el) => {
          if (oldCourseIds.indexOf(el.courseId) === -1) {
            el.studyNum = "";
            this.ruleForm.miniSubscribeSpecialCourses.push(el);
          } else {
            manyTimesMessage++;
          }
        });
        if (manyTimesMessage > 0) {
          this.$message({
            type: "warning",
            message: "存在" + manyTimesMessage + "条相同课程，已替您去重",
          });
        }
      } else {
        ret.map((el) => {
          el.studyNum = "";
        });
        this.ruleForm.miniSubscribeSpecialCourses = ret;
      }
    },

    // 关联课程 - 删除
    handleDel(miniSubscribeSpecialId, index) {
      this.ruleForm.miniSubscribeSpecialCourses.splice(index, 1);
    },

    // 编辑回显
    getDetail(miniSubscribeSpecialId) {
      this.$post(
        "/miniapp/subscribeSpecial/subscribeSpecialInfo",
        { miniSubscribeSpecialId },3000, true, 2
      ).then((ret) => {
        this.ruleForm = {
          miniSubscribeSpecialCourses: ret.data.miniCourseRelationListBOS,
          ...ret.data
        };
        editor.txt.html(ret.data.miniSubscribeSpecialInfo);
      });
    },

    // 新增&修改 - 保存按钮
    doAddSave(formName) {
      const params = {
        miniSubscribeSpecialName: this.ruleForm.miniSubscribeSpecialName,
        miniSubscribeSpecialInfo: this.ruleForm.miniSubscribeSpecialInfo,
        miniSubscribeSpecialCover: this.ruleForm.miniSubscribeSpecialCover,
        miniSubscribeSpecialTerm: this.ruleForm.miniSubscribeSpecialTerm,
        miniSubscribeSpecialFree: this.ruleForm.miniSubscribeSpecialFree,
        miniSubscribeSpecialCourses: this.ruleForm.miniSubscribeSpecialCourses
      };
      if (this.stu == "edit") {
        params.miniSubscribeSpecialId = this.ruleForm.miniSubscribeSpecialId;
      }
      if (!this.ruleForm.miniSubscribeSpecialFree) {
        params.miniSubscribeSpecialPrice =
          this.ruleForm.miniSubscribeSpecialPrice;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (editor.txt.text() == "") {
            this.$message.warning("请输入专栏详情");
            return false;
          } else {
            this.$post(
              this.stu == "add"?"/miniapp/subscribeSpecial/specialColumnInsert":
              "/miniapp/subscribeSpecial/subscribeSpecialModify",
              params,3000, true, 2
            )
              .then((res) => {
                if (res.status == "0") {
                  if (this.stu != "edit") {
                    this.$message({
                      type: "success",
                      message: "新增成功",
                    });
                  } else {
                    this.$message({
                      type: "success",
                      message: "修改成功",
                    });
                  }
                  this.dohandleOk();
                }
              })
              .catch(() => {
                return;
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    // 返回
    dohandleOk() {
      setTimeout(() => {
        this.$router.push({
          path: "/web/miniApp/thematicGrList",
          query: {
            refresh: true,
          },
        });
      }, 300);
    },
  },
};
</script>
<style lang="less" scoped>
h3 {
  line-height: 20px;
  border-left: 3px solid #5c6be8;
  padding-left: 4px;
  margin-bottom: 20px;
}
.flexcc {
  margin: 20px 0;
}
.el-input-number {
  width: 200px;
}
.el-table {
  margin-top: 20px;
  /deep/ .el-table__empty-block {
    min-height: 400px;
  }
}
</style>
